import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@type/index';
import { initialState } from '../slice';
import { selectListGroup, selectListScene } from './detailSceneSelectors';

const selectDomain = (state: RootState) => state.app || initialState;

/**
 * SECTION: DETAIL PROJECT SELECTORS
 */

export const selectDetailProject = createSelector(
  [selectDomain],
  state => state.project,
);

export const selectConfigAutoRotate = createSelector(
  [selectDomain],
  state => state.configAutoRotate || undefined,
);

export const selectConfigAutoChangeScene = createSelector(
  [selectDomain],
  state => state.configAutoChangeScene || undefined,
);

export const selectConfigSoundProject = createSelector(
  [selectDomain],
  state => state.configSoundProject || undefined,
);

export const selectIsMatterportProject = createSelector(
  [selectDetailProject],
  project => project?.project_type === 'matterport',
);

export const selectTitleProject = createSelector(
  [selectDetailProject],
  project => project?.title || undefined,
);

export const selectFirstSceneMediaProject = createSelector(
  [selectDetailProject],
  project => project?.first_scene?.media || undefined,
);

export const selectThumbnailProject = createSelector(
  [selectDetailProject],
  project => project?.thumbnail || undefined,
);

export const selectFeatureImageProject = createSelector(
  [selectDetailProject],
  project => project?.featured_image || undefined,
);

export const selectDetailFirstScene = createSelector(
  [selectDetailProject, selectListScene, selectListGroup],
  (project, listScene, listGroup) =>
    project?.config?.setting?.general?.first_scene
      ? listScene.filter(
          item => item.id === project?.config?.setting?.general?.first_scene,
        )[0] || listGroup[0].scenes[0]
      : listGroup.length
      ? listGroup[0].scenes[0]
      : undefined,
);

export const selectListPostUsedOnTour = createSelector(
  [selectDomain],
  state => state.listPost || [],
);
