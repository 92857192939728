import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@type/index';
import { initialState } from '../slice';

const selectDomain = (state: RootState) => state.app || initialState;

export const selectApp = createSelector(
  [selectDomain],
  dashboardState => dashboardState,
);

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.loading,
);

export const selectIsError = createSelector(
  [selectDomain],
  state => state.error,
);

export const selectIsSuccess = createSelector(
  [selectDomain],
  state => state.success,
);

export const selectToken = createSelector([selectDomain], state => state.token);
