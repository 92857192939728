import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@type/index';
import { initialState } from '../slice';

const selectDomain = (state: RootState) => state.app || initialState;

export const selectIsFirstScene = createSelector(
  [selectDomain],
  state => state.isFirstScene,
);

export const selectListScene = createSelector(
  [selectDomain],
  state => state.listScene || [],
);

export const selectListGroup = createSelector([selectDomain], state =>
  state.listGroup.length === 1 && state.listGroup[0].id === 'uncategory'
    ? state.listGroup
    : state.listGroup.filter(item => item.id !== 'uncategory'),
);

export const selectListSceneByGroup = createSelector(
  [selectListGroup],
  listGroup =>
    listGroup
      .reduce((result, item) => {
        result.push(item.scenes);
        return result;
      }, [])
      .flat(),
);

export const selectSceneSelected = createSelector(
  [selectDomain],
  state => state.sceneSelected || undefined,
);

export const selectPrevSceneSelected = createSelector(
  [selectDomain],
  state => state.prevSceneSelected || undefined,
);

export const selectHotspotSelected = createSelector(
  [selectDomain],
  state => state.hotspotSelected || undefined,
);
