import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@type/index';
import { initialState } from '../slice';
import { selectListScene } from './detailSceneSelectors';
import {
  onGetFormCapturesAttachedToHotspotIds,
  onGetFormCapturesAttachedToHotspotTypes,
  onGetFormCapturesAttachedToSceneIds,
  onGetHotspotIdsAttachedToFormCaptures,
  onGetHotspotTypesAttachedToFormCaptures,
  onGetSceneIdsAttachedToFormCaptures,
} from '../utils';

const selectDomain = (state: RootState) => state.app || initialState;

/**
 * SECTION: PLUGIN SELECTORS
 */

export const selectDataMap = createSelector(
  [selectDomain],
  state => state.dataMap || undefined,
);

export const selectConfigMap = createSelector(
  [selectDomain],
  state => state.configMap || undefined,
);

export const selectListFloorplan = createSelector(
  [selectDomain],
  state => state.listFloorplan || [],
);

export const selectConfigFloorplan = createSelector(
  [selectDomain],
  state => state.configFloorplan || undefined,
);

export const selectConfigCollaboration = createSelector(
  [selectDomain],
  state => state.configCollaboration || undefined,
);

export const selectConfigControlbar = createSelector(
  [selectDomain],
  state => state.configControlbar || undefined,
);

export const selectConfigLogo = createSelector(
  [selectDomain],
  state => state.configLogo || undefined,
);

export const selectConfigScreenshotCapture = createSelector(
  [selectDomain],
  state => state.configScreenshotCapture || undefined,
);

export const selectConfigNadir = createSelector(
  [selectDomain],
  state => state.configNadir || undefined,
);

export const selectConfigProtectPassword = createSelector(
  [selectDomain],
  state => state.configProtectPassword || undefined,
);

export const selectConfigCopyRight = createSelector(
  [selectDomain],
  state => state.configCopyRight || undefined,
);

export const selectConfigPopupIntro = createSelector(
  [selectDomain],
  state => state.configPopupIntro || undefined,
);

export const selectListCallToAction = createSelector(
  [selectDomain],
  state => state.listCallToAction || [],
);

// SECTION: PLUGIN FORM CAPTURE

export const selectListFormCapture = createSelector(
  [selectDomain],
  state => state.listFormCapture || [],
);

export const selectConfigFormCapture = createSelector(
  [selectDomain],
  state => state.configFormCapture || [],
);

export const selectHotspotSelectedTemporary = createSelector(
  [selectDomain],
  state => state.hotspotSelectedTemporary || null,
);

export const selectIsVisibleFormCapture = createSelector(
  [selectDomain],
  state => state.isVisibleFormCapture,
);

export const selectFormCaptureConfigSelected = createSelector(
  [selectDomain],
  state => state.formCaptureConfigSelected || undefined,
);

export const selectFormCaptureSelected = createSelector(
  [selectListFormCapture, selectFormCaptureConfigSelected],
  (list, config) =>
    config && config.form
      ? list.filter(item => item.id === config?.form)[0] || undefined
      : undefined,
);

export const selectListSceneIdToOpenFormCapture = createSelector(
  [selectConfigFormCapture],
  config => onGetSceneIdsAttachedToFormCaptures(config),
);

export const selectListFormCaptureConfigCorrespondingToListSceneId =
  createSelector([selectConfigFormCapture], config =>
    onGetFormCapturesAttachedToSceneIds(config),
  );

export const selectListHotspotTypeToOpenFormCapture = createSelector(
  [selectConfigFormCapture],
  config => onGetHotspotTypesAttachedToFormCaptures(config),
);

export const selectListFormCaptureConfigCorrespondingToListHotspotType =
  createSelector([selectConfigFormCapture], config =>
    onGetFormCapturesAttachedToHotspotTypes(config),
  );

export const selectListHotspotIdToOpenFormCapture = createSelector(
  [selectListScene, selectConfigFormCapture],
  (listScene, config) =>
    onGetHotspotIdsAttachedToFormCaptures(listScene, config),
);

export const selectListFormCaptureConfigCorrespondingToListHotspotId =
  createSelector(
    [selectListScene, selectConfigFormCapture],
    (listScene, config) =>
      onGetFormCapturesAttachedToHotspotIds(listScene, config),
  );

// !SECTION: FORM CAPTURE
